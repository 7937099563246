import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra Health
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Libra Health</h4>
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra Health </h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

            </div>
            <span className="my-4 mx-1 md:mx-0">
              <p className="playfair text-black text-xl md:text-3xl">Libra Health</p><br/>
              Libra, the last air sign, leads to the lower back, ovaries, kidneys, urinary tract diseases, and adrenal glands. The natives, containing afflicted Libra in their Horoscope, should care for conditions related to these organs. The ruling planet venus carries sway over skin, hair, veins, the throat, kidneys, and the lumbar region. You are excellent at reaching harmony in your health and diet. <br></br>
              <br></br>
              Also, you pick to build tranquility in all places of your life. For that, you carry a chord between time to work and time to chill. <br></br>
              <br></br>
              You tend to be overactive and restless. It would be best to bypass thoughtless activities as they can bring pressure and anxiety. You have a habit of performing under extreme conditions. Due to an overabundance of work, you may suffer nervous exhaustion, leading to poor dietary routines. Also, it may lead to dehydrated skin, hair, and sleeplessness. <br></br>
              <br></br>
              Due to frequent overload, you feel exhausted and powerless at times. Also, you may suffer from remembrance failure as well. So keeping quiet time and minimum simulation will significantly benefit you as it will permit your nervous system to restore. Will you be battling fit against anxiety and restlessness in the year 2024? <br></br> 
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Possible Health Concerns</p> <br/>
              Although you have a healthy skeletal structure, you usually struggle with a fuzzy aching in your lower back. The backache can be due to sitting in an exact position for longer. You are prone to kidney ailments and skin breakouts which can cause turmoil. <br></br>
              <br></br>
              Excess intake of alcohol may cause many problems in your body, and you need to avoid everything related to alcohol or smoking and those unhealthy food items that are difficult to digest. Consume plenty of water that will keep you hydrated and also helpful for you as it will maintain your system flushed and free from toxins. <br></br>
              <br></br>
              You may have problems related to stomach and bladder ailments and diseases related to the urinary tract. Libra women may encounter issues related to ovaries. You may also suffer from blood sugar levels associated problems. You should keep a check on a regular basis to know the exact information about your health. <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Food for Libra</p><br/>
              A good amount of sodium phosphate in your diet will be helpful as it equalizes the balance of acids and alkalis in the body and rids the body of waste material. This mineral's good food sources are whole grains, oats, apples, grapes, strawberries, raisins, steamed vegetables, spinach, tomatoes, peas, carrots, corn, nondairy cheeses, and vegan yogurt, asparagus, nuts, and almonds. Libra men and women function best with a high-protein diet with a small amount of fat, sugar, and acid-producing foods. Also, drinking plenty of water would be best to flush your systems and keep them free of toxins. <br></br>
              <br></br>
              You should bypass alcohol, smoking, meat, chocolates, carbonated beverages, aerated drinks, yeasty foods, and refined sugar as your sensitive skin will get impacted by its intake. Alcohol and carbonated drinks are bad for the kidneys.
              <br></br>
              2024 Yearly Report gives you the best guidance to direct yourself in a way that can surely improve your life with certain decisions related to your health. Learn more about your health; get your Free Personalized 2024 Report! <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Physical appearance</p> <br/>
              Libra men tend to be tall and possess a proportionate body. Libra women are of moderate height and made and managed to put on weight in middle age. This is why you should make it a habit to exercise regularly. You have a fascinating appearance, high forehead, bow-shaped mouth, and twirly or wavy hair. Usually, you have a pleasant facial expression and a soft smile. You may have a dimple on the cheek or chin. <br></br> 
              <br></br>
              A Libra woman has a pretty face, attractive features, and a classy figure. At the same time, Libra man is tall, cute, handsome, and with well-balanced elements. You can be comfortable and relaxed most of the time and say very sweetly to everyone. You may include a V-shaped chin line and almond-shaped eyes. <br></br>
              <br></br>
              <p className="playfair text-black text-xl md:text-3xl">Libra beauty</p><br/>
              Libra natives are ruled by Venus, the planet that rules aesthetics and all lovely things. You simply relish the beauty in anything, whether individual, craft, art, or music. Your houses are often filled with magnificent and incredible items. You appreciate owning the best money can buy. Also, you take satisfaction in your good taste. Your beauty ties into the fact that you are elegant, warm, friendly, social, classy, and passionate. <br></br>
              <br></br>
              Libra women are also called dimpled angels. Usually, good eyes and quality of magnificence emanate in most of you. You have a unique edge in looks and fashion, adorning yourselves as you naturally have poise and grace in you. You dress with courtesies in mind. Your entire costume will be balanced, modest, and shimmer with your eloquent speech and sense of humor. <br></br>
              <br></br>
              Each person has a unique personality that completes them indifferent to the rest. What about yours? To Know with your personalized Janampatri. Get your free janampatri from our best Astrologer! <br></br>
              <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
